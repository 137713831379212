import * as React from "react";
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import { Container } from "react-bootstrap";

const PageLayout = ({ data }) => {
  return (
    <Layout title={data.graphCmsPage.title}>
      <Container>
        <section>
          <h1>{data.graphCmsPage.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: data.graphCmsPage.content.html }}/>
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    graphCmsPage(id: {eq: $id}) {
      content {
        html
      }
      title
    }
  }
`

export default PageLayout
